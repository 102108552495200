import { createSlice } from "@reduxjs/toolkit";
import { getPosts, getUserPosts, checkUser, login } from "./thunkRequests";

export const dataSlice = createSlice({
  name: "data",
  initialState: {
    user: localStorage.getItem("user"),
    posts: [],
    userPosts: [],
    post: "",
    clickedUser: "",
    postsClickedUser: [],
  },
  reducers: {
    setPost: (state, action) => {
      state.post = action.payload;
    },
    setClickedUser: (state, action) => {
      state.clickedUser = action.payload;
    },
    logout: (state) => {
      logoutFunction(state);
    },
  },
  extraReducers: {
    [getPosts.fulfilled]: (state, action) => {
      state.posts = action.payload;
    },
    [getUserPosts.fulfilled]: (state, action) => {
      state.userPosts = action.payload;
      state.postsClickedUser = action.payload;
    },
    [checkUser.fulfilled]: (state, action) => {
      if (action.payload === 401) logoutFunction(state);
    },
    [login.fulfilled]: (state, action) => {
      if (action.payload.status === 200) {
        localStorage.setItem("user", action.payload.user);
        state.user = action.payload.user;
        window.location.hash = "/";
      } else {
        alert("Login Fault");
      }
    },
  },
});

const logoutFunction = (state) => {
  fetch("api/logout", { method: "POST" });
  localStorage.setItem("user", "");
  state.user = "";
};

export const {
  setUserPosts,
  setPost,
  setClickedUser,
  setPostsClickedUser,
  logout,
} = dataSlice.actions;

export default dataSlice.reducer;
