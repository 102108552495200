import "./Login.scss";
import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { login } from "../redux/thunkRequests";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/dataSlice";

const Login = () => {
  const user = useSelector((state) => state.data.user);
  const userNameRef = useRef();
  const passwortRef = useRef();
  const dispatch = useDispatch();

  const submit = () => {
    const user = userNameRef.current.value;
    const pass = passwortRef.current.value;
    user && pass
      ? dispatch(login({ name: user, pass: pass }))
      : alert("Enter your data");
  };

  const jsx1 = (
    <div className="Login">
      <label className="field">
        <span>User Name: </span>
        <input ref={userNameRef} type="text"></input>
      </label>
      <label className="field">
        <span>Passwort: </span>
        <input
          ref={passwortRef}
          onKeyPress={(e) => e.key === "Enter" && submit()}
          type="password"
        ></input>
      </label>
      <br />
      <div className="login_buttons">
        <button type="button" onClick={submit}>
          Login
        </button>
        <NavLink to="/">
          <button type="button">Cancel</button>
        </NavLink>
      </div>
      <NavLink to="/Signup">
        <p type="button">New user? Sign up</p>
      </NavLink>
    </div>
  );

  const jsx2 = (
    <div className="Login">
      <div className="login-info">
        <>You are logged as {user}</>
      </div>
      <div className="login_buttons">
        <button type="button" onClick={() => dispatch(logout())}>
          Logout
        </button>
        <NavLink to="/">
          <button type="button">Cancel</button>
        </NavLink>
      </div>
    </div>
  );

  return <>{!user ? jsx1 : jsx2}</>;
};

export default Login;
