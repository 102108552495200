import { useState, useEffect } from "react";
import { getCommentLikes, postData } from "../requests";
import { BiLike as LikeIcon } from "react-icons/bi";

const CommentsLike = ({ e, user }) => {
  const [likeCount, setLikeCount] = useState(0);

  useEffect(() => {
    getCommentLikes(e.id).then((res) => setLikeCount(res));
  }, [e.id]);

  const newLike = (id) => {
    if (user) {
      postData({ post_id: id, user: user }, "POST", "commentLikes").then(
        (res) => {
          if (res === 'added') setLikeCount(likeCount + 1);
          else if (res === 'deleted') setLikeCount(likeCount - 1);
        }
      );
    } else alert("You must be logged in");
  };
  return (
    <p className="like-count" onClick={() => newLike(e.id)}>
      <LikeIcon className="icon" /> <label>&nbsp;{likeCount} &nbsp;</label>
    </p>
  );
};

export default CommentsLike;
