import "./EditPosts.scss";
import Post from "./Post";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPosts, checkUser } from "../redux/thunkRequests";

const Main = () => {
  const [state, setState] = useState(1);
  const posts = useSelector((state) => state.data.posts);
  const user = useSelector((state) => state.data.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
    if (user) dispatch(checkUser(user));
  }, [state, user, dispatch]);

  return (
    <div className="Main">
      {posts
        .map((e, i) => <Post key={i} post={e} setMainState={setState} />)
        .reverse()}
    </div>
  );
};

export default Main;
