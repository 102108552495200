import "./Article.scss"
import PostInfo from "./PostInfo";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getComments } from "../requests";
import { useSelector } from "react-redux";
import Comments from "./Comments";

const Article = () => {
  const navigate = useNavigate();
  const post = useSelector((state) => state.data.post);
  const [comments, setComments] = useState([]);
  const [postState, setPostState] = useState(1);
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    if (!post) navigate("/");
  });

  useEffect(() => {
    getComments(post.id).then((res) => setComments(res));
  }, [post.id, postState]);

  const click = () => navigate("/");

  let imgSrc;
  if (post.img) imgSrc = `./pics/${post.img}`;
  return (
    <>
      {post && (
        <div className="Article">
          <button className="back-button" onClick={() => click()}>
            back to Main Page
          </button>
          <div className="Post-title">{post.title}</div>
          <PostInfo
            post={post}
            setShowComments={setShowComments}
            showComments={showComments}
          />
          {showComments && (
            <Comments
              id={post.id}
              comments={comments}
              postState={postState}
              setPostState={setPostState}
            />
          )}
          <img src={imgSrc} alt="" onClick={() => click()}></img>
          <div
            className="Article-Body"
            dangerouslySetInnerHTML={{ __html: post.body }}
          ></div>
        </div>
      )}
    </>
  );
};
export default Article;
