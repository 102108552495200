import { createAsyncThunk } from "@reduxjs/toolkit";

export const getPosts = createAsyncThunk("data/getPosts", () =>
  fetch(`api/posts`).then((res) => res.json())
);

export const getUserPosts = createAsyncThunk("data/getUserPosts", (user) =>
  fetch(`api/userPosts/${user}`).then((res) => res.json())
);

export const checkUser = createAsyncThunk("data/checkUser", (user) =>
  fetch(`api/checkUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({ user: user }),
  }).then((res) => res.json())
);

export const login = createAsyncThunk("data/login", (data) =>
  fetch("api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json())
);

export const signUp = (data) =>
  fetch("api/signUp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      res.status === 200
        ? (window.location.hash = "/Login")
        : alert("User exist");
    });
