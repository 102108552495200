import { CKEditor } from "ckeditor4-react";

const CKe = ({ prop, output, setOutput }) => {
  return (
    <div>
      <CKEditor
        {...prop}
        initData={prop}
        data={prop}
        //editorUrl="//cdn.ckeditor.com/4.18.0/full/ckeditor.js"
        onChange={(evt) => {
          setOutput(evt.editor.getData());
        }}
      />
      {/* <div dangerouslySetInnerHTML={{ __html: output }}></div> */}
    </div>
  );
};

export default CKe;
