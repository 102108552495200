import "./Header.scss";
import HamMenu from "./HamMenu";
import { NavLink } from "react-router-dom";
import { BiUser as UserIcon } from "react-icons/bi";
import { useSelector } from "react-redux";

const Header = () => {
  const user = useSelector((state) => state.data.user);
  
  let headerClass = "header withUser";
  if (!user) headerClass = "header";
  return (
    <div className="header0">
      <HamMenu />
      <div className={headerClass}>
        <span className="title">
          <NavLink to="/">Posts</NavLink>
        </span>
        <NavLink to="/Login" className="userIcon userName">
          {user ? user : "Login"}
        </NavLink>
        <NavLink to="/Login" className="userIcon">
          <UserIcon size={30} />
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
