const isToday = (date) => {
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return (
      "Today " +
      date.toLocaleString("de-DE", {
        hour: "numeric",
        minute: "numeric",
      })
    );
  }
  return false;
};
const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (yesterday.toDateString() === date.toDateString()) {
    return (
      "Yesterday at " +
      date.toLocaleString("de-DE", {
        hour: "numeric",
        minute: "numeric",
      })
    );
  }
  return false;
};

export const checkDate = (date) => {
  if (isYesterday(date)) return isYesterday(date);
  else if (isToday(date)) return isToday(date);
  else return (date.toLocaleString("en", {
    day: "numeric",
    month: "short", // short or 2-digit
    year: "numeric"
  }) + ' at ' + date.toLocaleString("us", {
    hour: "numeric",
    minute: "numeric"
  }))
};

