import "./Post.scss";
import PostInfo from "./PostInfo";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postData, getComments } from "../requests";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "../redux/dataSlice";
import Comments from "./Comments";

const Post = ({ post, setMainState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.data.user);
  const [comments, setComments] = useState([]);
  const [postState, setPostState] = useState(1);
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    getComments(post.id).then((res) => setComments(res));
  }, [post.id, postState]);

  const toArticle = (e) => {
    dispatch(setPost(e));
    navigate("/Article");
  };

  const editPost = (e) => {
    dispatch(setPost(e));
    navigate("/EditPost");
  };
  const deletePost = (e) => {
    if (window.confirm("Delete this Post?")) {
      postData(e, "DELETE", "delete");
      setMainState((prev) => prev + 1);
    }
  };

  let imgSrc;
  if (post.img) imgSrc = `./pics/${post.img}`;
  return (
    <div className="Post">
      <div onClick={() => toArticle(post)}>
        <div className="Post-title">{post.title}</div>
        <div className="Post-subtitle">{post.subtitle}</div>
        <img src={imgSrc} alt=""></img>
      </div>
      <PostInfo
        post={post}
        setShowComments={setShowComments}
        showComments={showComments}
      />
      {showComments && (
        <Comments
          id={post.id}
          comments={comments}
          postState={postState}
          setPostState={setPostState}
        />
      )}
      {(user === post.user || user === "Admin") && (
        <div>
          <button className="back-button" onClick={() => editPost(post)}>
            Edit
          </button>
          <button className="back-button" onClick={() => deletePost(post)}>
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

export default Post;
