import "./HamMenu.scss";
import { React, useState } from "react";
import { NavLink } from "react-router-dom";
// import { stack as Menu } from "react-burger-menu";
import { slide as Menu } from "react-burger-menu";

const HamMenu = () => {
  const [open, setOpen] = useState(false);

  const closeMenu = () => {
    setOpen(false);
  };

  const openMenu = () => {
    setOpen(true);
  };

  const MenuLinks = [
    { name: "Home", link: "/" },
    { name: "New Post", link: "/NewPost" },
  ];

  const RenderLinks = MenuLinks.map((e, i) => (
    <NavLink
      to={e.link}
      key={i}
      className="menuItem1"
      onClick={() => closeMenu()}
    >
      {e.name}
    </NavLink>
  ));
  return (
    <div className="header0">
      <Menu
        right
        isOpen={open}
        onOpen={openMenu}
        onClose={closeMenu}
        width={292}
      >
        {RenderLinks}
        <NavLink
          to="/UserPosts"
          className="menuItem1"
          onClick={() => closeMenu()}
        >
          My Posts
        </NavLink>
      </Menu>
    </div>
  );
};
export default HamMenu;
