import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../requests";
import Upload from "./Upload";
import CKeditor from "./CKeditor";
import { useSelector } from "react-redux";

const NewPost = () => {
  const [imgName, setImgName] = useState(null);
  const [output, setOutput] = useState("");
  const [body] = useState("");
  const titleRef = useRef();
  const SubtitleRef = useRef();
  const imgRef = useRef();
  const errorUser = useRef();
  const errorTitle = useRef();
  const errorSubtitle = useRef();
  const errorImg = useRef();
  const navigate = useNavigate();
  const user = useSelector((state) => state.data.user);

  useEffect(() => {
    if (!user) navigate("/Login");
  }, [user, navigate]);

  const save = () => {
    let title = titleRef.current.value;
    let Subtitle = SubtitleRef.current.value;
    let imgSource;
    if (imgRef.current.value) {
      imgSource = imgRef.current.value;
    }
    if (imgName) {
      imgSource = imgName;
    }
    let status1 = false;
    let status2 = false;
    let status4 = false;

    if (!user) {
      errorUser.current = "Enter Data";
    } else {
      errorUser.current = null;
      status4 = true;
    }
    if (!title) {
      errorTitle.current = "Enter Data";
    } else {
      errorTitle.current = null;
      status1 = true;
    }
    if (!Subtitle) {
      errorSubtitle.current = "Enter Data";
    } else {
      errorSubtitle.current = null;
      status2 = true;
    }
    if (status1 && status2 && status4) {
      let cb = {
        user: user,
        title: titleRef.current.value,
        Subtitle: SubtitleRef.current.value,
        Body: output,
        imgSource: imgSource,
      };
      postData(cb, "POST", "post");
      navigate("/");
    }
  };

  const cancel = () => {
    navigate("/");
  };

  return (
    <div className="Edit">
      <h2>New Post</h2>
      <label className="field">
        <span>Title: </span>
        <textarea ref={titleRef} type="text" />{" "}
        <span className="error"> {errorTitle.current}</span>
      </label>
      <label className="field">
        <span>Subtitle: </span>
        <textarea ref={SubtitleRef} type="text" />{" "}
        <span className="error"> {errorSubtitle.current}</span>
      </label>
      <CKeditor prop={body} output={output} setOutput={setOutput} />
      <h3 className="preview">Preview:</h3>
      <div className="preview" dangerouslySetInnerHTML={{ __html: output }}></div>
      {!imgName ? (
        <label className="field">
          <span>Image Source: </span>
          <textarea defaultValue={imgName} ref={imgRef} type="text" />
          <span className="error"> {errorImg.current}</span>
        </label>
      ) : (
        <p ref={imgRef}>Image Source: &nbsp; {imgName}</p>
      )}
      <Upload setSource={setImgName}></Upload>
      <p>
        <button className="back-button" onClick={save}>
          Save
        </button>
        <button className="back-button" onClick={cancel}>
          Cancel
        </button>
      </p>
    </div>
  );
};

export default NewPost;
