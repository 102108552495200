export const getComments = (id) =>
  fetch(`api/comments/${id}`).then((res) => res.json());

export const getLikes = (id) =>
  fetch(`api/getLikes/${id}`).then((res) => res.json());

export const getCommentLikes = (id) =>
  fetch(`api/getCommentLikes/${id}`).then((res) => res.json());

export const postData = async (data, MET, url) => {
  return await fetch(`api/${url}`, {
    method: MET,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};
