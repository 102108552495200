import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../requests";
import { useSelector } from "react-redux";

const NewComment = () => {
  const navigate = useNavigate();
  const userRef = useRef();
  const BodyRef = useRef();
  const errorUser = useRef();
  const errorBody = useRef();
  const commentId = useSelector((state) => state.comments.comment);

  const save = () => {
    let user = userRef.current.value;
    let Body = BodyRef.current.value;
    let status1 = false;
    let status2 = false;

    if (!user) {
      errorUser.current = "Enter Data";
    } else {
      errorUser.current = null;
      status1 = true;
    }
    if (!Body) {
      errorBody.current = "Enter Data";
    } else {
      errorBody.current = null;
      status2 = true;
    }
    if (status1 && status2) {
      let cb = {
        user: userRef.current.value,
        comment: BodyRef.current.value,
        post_id: commentId,
      };
      postData(cb, "POST", "newComment");
      navigate("/");
    }
  };

  const cancel = () => navigate("/");

  return (
    <div className="Edit">
      <h3>New Comment</h3>
      <label className="field">
        <span>User: </span>
        <textarea ref={userRef} type="text" />{" "}
        <span className="error"> {errorUser.current}</span>
      </label>
      <label className="field field-body">
        <span>Body-Text: </span>
        <textarea ref={BodyRef} type="text"></textarea>
        <span className="error"> {errorBody.current}</span>
      </label>
      <p>
        <button className="back-button" onClick={save}>
          Save
        </button>
        <button className="back-button" onClick={cancel}>
          Cancel
        </button>
      </p>
    </div>
  );
};

export default NewComment;
