const uploadUrl = `api/upload`;

const Upload = ({ setSource, save }) => {
  const onFileChange = (event) => {
    const imgSource = `${event.target.files[0].name}`;
    setSource(imgSource);
  };
  return (
    <div className="Upload">
      <iframe name="dummyframe" title="dummyframe"></iframe>
      <form
        method="post"
        action={uploadUrl}
        target="dummyframe"
        encType="multipart/form-data"
      >
        <div>
          <input
            className="input-file"
            type="file"
            name="files"
            onChange={onFileChange}
          />
          <span>&nbsp;</span>
          <button
            className="back-button"
            type="submit"
            name="Upload"
            value="Upload"
            onClick={(e) => alert("Uploaded")}
          >
            Upload
          </button>
        </div>
      </form>
    </div>
  );
};

export default Upload;
