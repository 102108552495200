import "bootstrap/dist/css/bootstrap.min.css";
import "./Comments.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../requests";
import CommentsLike from "./CommentsLike";
import { useSelector, useDispatch } from "react-redux";
import { setComment } from "../redux/commentsSlice";

const Comments = ({ id, comments, setPostState, postState }) => {
  // const [comments, setComments] = useState([]);
  // const [commentsState, setCommentsState] = useState(1);
  const [showClass, setShowClass] = useState("Comments");
  const user = useSelector((state) => state.data.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setShowClass("Comments show");
    // getComments(id).then((res) => setComments(res));
  }, [postState]);

  const deleteItem = (e) => {
    if (window.confirm("Delete this Comment?")) {
      postData({ id: e.id }, "DELETE", "deleteComment");
      // setCommentsState(commentsState + 1);
      setPostState(postState + 1);
    }
  };
  const newComment = () => {
    dispatch(setComment(id));
    navigate("/NewComment");
  };

  return (
    <div>
      {id && (
        <div className={showClass}>
          {comments
            .map((e, i) => (
              <div key={i} className="Comment">
                <span>
                  <h5>{e.user}:</h5>
                  <p>{e.comment}</p>
                </span>
                <CommentsLike e={e} user={user} />
                <button
                  className={
                    user === "Admin" || user === "admin"
                      ? "btn btn-danger"
                      : "btn btn-danger hidden"
                  }
                  onClick={() => deleteItem(e)}
                >
                  x
                </button>
              </div>
            ))
            .reverse()}
          <button className="back-button showBtn" onClick={() => newComment()}>
            Write Comment
          </button>
        </div>
      )}
    </div>
  );
};
export default Comments;
