import Post from "./Post";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PostsOdClickedUser = () => {
  const [state, setState] = useState(1);
  const navigate = useNavigate();
  const back = () => navigate("/");
  const clickedUser = useSelector((state) => state.data.clickedUser);
  const postsClickedUser = useSelector((state) => state.data.postsClickedUser);

  return (
    <div className="PostsOfClickedUser">
      <div className="buttons">
        <button className="back-button" onClick={() => back()}>
          back to Main Page
        </button>
      </div>
      <h4 className="title">Posts of {clickedUser}</h4>
      <div className="Main">
        {postsClickedUser
          .map((e, i) => (
            <Post key={i} post={e} state={state} setState={setState} />
          ))
          .reverse()}
      </div>
    </div>
  );
};

export default PostsOdClickedUser;
