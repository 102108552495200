import "./PostInfo.scss";
import { BiCommentDetail as CommentIcon } from "react-icons/bi";
import { BiLike as LikeIcon, BiUserCircle as UserIcon } from "react-icons/bi";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postData, getComments, getLikes } from "../requests";
import { checkDate } from "../helpers/date";
import { useDispatch, useSelector } from "react-redux";
import { setClickedUser } from "../redux/dataSlice";
import { getUserPosts } from "../redux/thunkRequests";

const PostInfo = ({ post, showComments, setShowComments }) => {
  const user = useSelector((state) => state.data.user);
  const [likeCount, setLikeCount] = useState(0);
  const [commentslength, setLength] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getLikes(post.id).then((res) => setLikeCount(res));
    getComments(post.id).then((res) => setLength(res.length));
  }, [post.like_count, post.id]);

  const newLike = (id) => {
    if (user) {
      postData({ post_id: id, user: user }, "POST", "likes").then((res) => {
        res === "added"
          ? setLikeCount(likeCount + 1)
          : setLikeCount(likeCount - 1);
      });
    } else navigate("/Login");
  };
  const comments = () => {
    showComments ? setShowComments(false) : setShowComments(true);
  };

  const postsOfClickedUser = (user) => {
    dispatch(getUserPosts(user));
    dispatch(setClickedUser(user));
    navigate("/PostsOfClickedUser");
  };

  return (
    <div className="Post-Info">
      <label className="user" onClick={() => postsOfClickedUser(post.user)}>
        <UserIcon className="icon" size={18} />
        <span className="username">&nbsp;{post.user}</span>
        <span className="time">&nbsp;{checkDate(new Date(post.time))}</span>
      </label>
      <div className="right">
        <label className="like-count" onClick={() => newLike(post.id)}>
          <LikeIcon className="icon" /> <span>&nbsp;{likeCount} &nbsp;</span>
        </label>
        <label className="comments-count" onClick={() => comments()}>
          <CommentIcon className="icon comment-icon" size={13} />{" "}
          <span>&nbsp;{commentslength}</span>
        </label>
      </div>
    </div>
  );
};
export default PostInfo;
