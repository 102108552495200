import "./EditPosts.scss";
import Post from "./Post";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserPosts, checkUser } from "../redux/thunkRequests";

const UserPosts = () => {
  const [state, setState] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPosts = useSelector((state) => state.data.userPosts);
  const user = useSelector((state) => state.data.user);

  useEffect(() => {
    if (user) {
      dispatch(checkUser(user));
      dispatch(getUserPosts(user));
    } else navigate("/Login");
  }, [state, user, navigate, dispatch]);

  return (
    <div className="Main">
      <div className="Posts">
        {userPosts
          .map((e, i) => (
            <Post key={i} post={e} state={state} setState={setState} />
          ))
          .reverse()}
      </div>
    </div>
  );
};

export default UserPosts;
