import { configureStore } from "@reduxjs/toolkit";
import data from "./dataSlice";
import comments from "./commentsSlice";

const store = configureStore({
  reducer: {
    data: data,
    comments: comments,
  },
});

export default store;
