import "./App.scss";
import "./components/EditPosts.scss";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Main from "./components/Main";
import UserPosts from "./components/UserPosts";
import PostsOfClickedUser from "./components/PostsOfClickedUser";
import NewPost from "./components/NewPost";
import Article from "./components/Article";
import NewComment from "./components/NewComment";
import EditPost from "./components/EditPost";
import Post from "./components/Post";

const App = () => {
  return (
    <div className="ParrentApp">
      <Header />
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="Article" element={<Article />} />
          <Route path="UserPosts" element={<UserPosts />} />
          <Route path="PostsOfClickedUser" element={<PostsOfClickedUser />} />
          <Route path="NewPost" element={<NewPost />} />
          <Route path="Login" element={<Login />} />
          <Route path="Signup" element={<Signup />} />
          <Route path="NewComment" element={<NewComment />} />
          <Route path="Post" element={<Post />} />
          <Route path="EditPost" element={<EditPost />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
